import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import StartPage from "./StartPage.js";
import TestPage from "./TestPage.js";


const AppRouter = () => (
  <Router>
    <div>
    <Routes>
      <Route path="/" exact element={<StartPage/>} />
      <Route path="/test" exact element={<TestPage/>} />
    </Routes>
    </div>
  </Router>
);

export default AppRouter;
