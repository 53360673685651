class InvalidHostPort extends Error { }
class InvalidIP extends Error { }

class Target {

    constructor(host, port, ip) {
        this.host = host;
        this.port = port;
        this.ip = ip;
    }

    get hostPort() {
        if (this.port === 636) {
            return this.host;
        } else {
            return this.host + ':' + this.port;
        }
    }

    get queryString() {
        let params = {
            'target': this.hostPort,
        }
        if (this.ip) {
            params['ip'] = this.ip;
        }
        let searchParams = new URLSearchParams(params);
        return searchParams.toString();
    }

    static parseHostPort(hostPort) {
        hostPort = hostPort.replace(/\s/g, ''); // Silently remove all whitespace in string, they were probably added by accident.
        hostPort = hostPort.toLowerCase(); // Silently convert to lowercase.
        var [host, port] = hostPort.split(':');
        if (port === undefined) {
            port = '636';
        }
        const fqdnRegex = /^[a-z0-9-]+(?:\.[a-z0-9-]+)+$/;
        if (!fqdnRegex.test(host)) {
            throw new InvalidHostPort(); // Doesn't look like a FQDN.
        }
        const lettersRegex = /[a-z]/;
        if (!lettersRegex.test(host)) {
            throw new InvalidHostPort(); // No letters in domain name -- has the user entered an IP address?
        }
        const portRegex = /^\d+$/;
        if (!portRegex.test(port)) {
            throw new InvalidHostPort(); // The port number must be an integer.
        }
        port = parseInt(port);
        if (port <= 0 || port > 65535) {
            throw new InvalidHostPort(); // Port number out of range
        }
        return [host, port];
    }

    static parseIP(ip) {
        ip = ip.replace(/\s/g, ''); // Silently remove all whitespace in string, they were probably added by accident.
        ip = ip.toLowerCase(); // Silently convert to lowercase.
        const ipv4Regex = /^\d+\.\d+\.\d+\.\d+$/;
        if (ipv4Regex.test(ip)) {
            return ip;
        }
        const ipv6Characters = /^[0-9a-f:]+$/;
        if (!ipv6Characters.test(ip)) {
            throw new InvalidIP(); // Invalid characters in IPv6 address.
        }
        if (!ip.includes(':')) {
            throw new InvalidIP(); // We need at least one colon in an IPv6 address.
        }
        return ip; // This looks like it may be an IPv6 address.
    }

    static fromLocation(location) {
        let params = new URLSearchParams(location.search);
        let [host, port] = Target.parseHostPort(params.get('target'));
        let ip = null;
        if (params.has('ip')) {
            ip = params.get('ip');
            ip = Target.parseIP(ip);
        }
        return new Target(host, port, ip);
    }

}

export default Target;
export {InvalidHostPort, InvalidIP, Target};
