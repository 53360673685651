import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Target, InvalidHostPort, InvalidIP } from './Target.js';

const TestInputForm = (props) => {
  const [hostPort, setHostPort] = useState('');
  const [invalidHostPort, setInvalidHostPort] = useState(false);
  const [ip, setIP] = useState('');
  const [invalidIP, setInvalidIP] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.initialTarget) {
      setHostPort(props.initialTarget.hostPort);
      setIP(props.initialTarget.ip || '');
    }
  }, [props.initialTarget])

  const handleHostPortChange = (event) => {
    setHostPort(event.target.value);
  }

  const handleIPChange = (event) => {
    setIP(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let invalidHostPort = false;
    let host, port;
    try {
      [host, port] = Target.parseHostPort(hostPort);
    } catch (e) {
      if (!(e instanceof InvalidHostPort)) {
        throw e;
      }
      invalidHostPort = true;
    }
    let invalidIP = false;
    let ipAddr = null;
    try {
      if (ip.trim()) {
        ipAddr = Target.parseIP(ip);
      }
    } catch (e) {
      if (!(e instanceof InvalidIP)) {
        throw e;
      }
      invalidIP = true;
    }
    setInvalidHostPort(invalidHostPort);
    setInvalidIP(invalidIP);
    if (invalidHostPort || invalidIP) {
      return;
    }
    let target = new Target(host, port, ipAddr);
    navigate(`/test?${target.queryString}`);
  }

  let hostPortError = null;
  let hostPortClass = 'form-control';
  if (invalidHostPort) {
    hostPortError = <div className="invalid-feedback">Invalid target</div>;
    hostPortClass += ' is-invalid';
  }
  let ipError = null;
  let ipClass = 'form-control';
  if (invalidIP) {
    ipError = <div className="invalid-feedback">Invalid IP address</div>;
    ipClass += ' is-invalid';
  }
  return (
    <div className="mt-4 mb-4 p-5 bg-light rounded">
      <h1>LDAP connection test</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>
            Target:
            <input className={hostPortClass} type="text" autoFocus value={hostPort} onChange={handleHostPortChange} placeholder="ldap.example.com:636" required />
            {hostPortError}
          </label>
        </div>
        <div className="mb-3">
          <label>
            Override IP address:
            <input className={ipClass} type="text" value={ip} onChange={handleIPChange} placeholder="192.0.2.42" />
            {ipError}
          </label>
        </div>
        <input className="btn btn-primary" type="submit" value="Submit" />
      </form>
    </div>
  );
}

export default TestInputForm;
