import React from 'react';
import {
  useLocation,
  useNavigate,
} from "react-router-dom";

import Target from './Target.js';
import TestInputForm from './TestInputForm.js';
import TestResults from './TestResults.js';

const TestPage = () => {

  let location = useLocation();
  let navigate = useNavigate();

  const getTarget = () => {
    try {
      return Target.fromLocation(location);
    } catch (e) {
      console.log('Error extracting target from query string: ' + e);
      return null;
    }
  }

  let target = getTarget();
  if (target === null) {
      navigate("/");
  }

  return (
      <div className="App" key={location.key}>
        <TestInputForm initialTarget={target} />
        <TestResults target={target} />
      </div>
    );
}

export default TestPage;
