import React from 'react';

import TestInputForm from './TestInputForm.js'

const StartPage = () => (
    <div className="App">
      <TestInputForm />
    </div>
);

export default StartPage;
