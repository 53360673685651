import React, { useEffect, useState } from 'react';

import TestResult from './TestResult.js';

const TestResults = (props) => {
    const [ready, setReady] = useState(false);
    const [results, setResults] = useState(null);

    useEffect(() => {
        const fetchResult = async () => {
            let request = {
                'host': props.target.host,
                'port': props.target.port,
            }
            if (props.target.ip) {
                request['ip'] = props.target.ip;
            }
            let response = await fetch(process.env.REACT_APP_BACKEND_ENDPOINT + 'test', {
                method: 'POST',
                body: new URLSearchParams(request),
            });
            let data = await response.json();
            setReady(true);
            setResults(data);
        }

        fetchResult();
    }, [props.target.host, props.target.port, props.target.ip]);

    const buildResult = (result) => {
        let target = props.target.hostPort;
        if (result && result.ip) {
            target += ` (${result.ip})`
        }
        return <TestResult key={target} target={props.target} result={result} />
    }

    let res;
    if (ready) {
        res = results.map((result) => buildResult(result));
    } else {
        res = [buildResult(null)]
    }
    return (
        <ul className="list-group">
            {res}
        </ul>
    );
}

export default TestResults;
