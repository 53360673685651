import React, { useEffect, useState } from 'react';

import styles from './TestResult.module.css';

const TestResult = (props) => {

    const [detailsVisible, setDetailsVisible] = useState(false);
    const [pendingFirewallTests, setPendingFirewallTests] = useState(false);
    const [firewallTestProblems, setFirewallTestProblems] = useState([]);

    useEffect(() => {
        const testFirewall = async () => {
            const request = {
                'destination_ip': props.result.ip,
                'destination_port': props.target.port,
            }
            const response = await fetch(process.env.REACT_APP_BACKEND_ENDPOINT + 'fwtest/test', {
                method: 'POST',
                body: JSON.stringify(request),
            });
            const responseData = await response.json();
            const problems = [];
            for (const entry of responseData['results']) {
                if (entry['ok']) {
                    continue;
                }
                problems.push({
                    'severity': 'error',
                    'message': `Server not reachable from ${entry['ip']}: ${entry['error_message']}`,
                });
            }
            setPendingFirewallTests(false);
            setFirewallTestProblems(problems);
        }

        if (props.result !== null) {
            setPendingFirewallTests(true);
            testFirewall();
        }
    }, [props.result, props.target.port])

    const toggleDetails = (e) => {
        e.preventDefault();
        setDetailsVisible(!detailsVisible);
    }

    const getTargetString = () => {
        let ret = props.target.hostPort;
        if (props.result) {
            ret += ` (${props.result.ip})`
        }
        return ret;
    }

    const getStatus = () => {
        if (props.result === null) {
            return 'pending';
        } else if (pendingFirewallTests) {
            return 'pending';
        } else if (getProblems().some((e) => { return e.severity === 'error' })) {
            return 'error';
        } else if (getProblems().some((e) => { return e.severity === 'warning' })) {
            return 'warning';
        } else {
            return 'ok';
        }
    }

    const getStatusElement = () => {
        let targetString = getTargetString();
        let status = getStatus();
        switch (status) {
            case 'pending':
                return (
                    <div>
                        <div className="spinner-border" role="status" />
                        <span className="ml-3">Testing {targetString}...</span>
                    </div>
                );
            case 'ok':
            case 'warning':
                return <div>Successfully connected to {targetString}.</div>;
            case 'error':
                return <div>Error connecting to {targetString}:</div>;
            default:
                throw new Error(`Missing handler for status ${status}`);
        }
    }

    const getProblems = () => {
        let problems = [];
        if (props.result) {
            problems = props.result.problems;
        }
        problems = problems.concat(firewallTestProblems);

        problems = [...problems].sort((a, b) => {
            if (a.severity === b.severity) {
                return a.message.localeCompare(b.message);
            } else if (a.severity === 'error') {
                return -1;
            } else {
                return 1;
            }
        });
        return problems;
    }

    const getProblemsElement = () => {
        let problems = getProblems().map((problem, index) => {
            const className = styles[`problem-${problem.severity}`];
            return <li key={index} className={className}>{problem.message}</li>;
        });
        if (!problems) {
            return null;
        }
        return (
            <div>
                <ul>
                    {problems}
                </ul>
            </div>
        );
    }

    const getDetails = () => {
        if (!props.result) {
            return null;
        }
        let elements = [];
        function addDetail(title, content) {
            elements.push((
                <React.Fragment key={title}>
                    <div className={styles.detailsTitle}>{title}</div>
                    <div className={styles.detailsContent}>{content}</div>
                </React.Fragment>
            ));
        }
        if (props.result.protocol) {
            addDetail('Protocol', props.result.protocol)
        }
        if (props.result.cipher) {
            addDetail('Cipher', props.result.cipher);
        }
        if (props.result.certificates) {
            function formatCertificate(certificate, index) {
                const pemURL = 'data:text/plain;base64,' + window.btoa(certificate.pem);
                return <li key={index}>{certificate.subject} (<a href={pemURL} download='certificate.pem'>Download PEM</a>)</li>;
            }
            const certificates = (
                <ul>
                    {props.result.certificates.map(formatCertificate)}
                </ul>
            );
            addDetail('Certificates', certificates);
        }
        if (elements.length === 0) {
            return null;
        }

        let arrow;
        let detailsElement;
        if (!detailsVisible) {
            arrow = '▸';
            detailsElement = null;
        } else {
            arrow = '▾';
            detailsElement = <div className={styles.detailsList}>{elements}</div>;
        }
        return (
            <div className={styles.details}>
                <div><button className={`btn btn-link ${styles.detailsToggle}`} onClick={toggleDetails}>{arrow}Details</button></div>
                {detailsElement}
            </div>
        )
    }

    const statusItemType = {
        'pending': 'info',
        'ok': 'success',
        'warning': 'warning',
        'error': 'danger',
    };
    const className = `list-group-item list-group-item-${statusItemType[getStatus()]}`;
    return (
        <li className={className}>
            {getStatusElement()}
            {getProblemsElement()}
            {getDetails()}
        </li>
    );
}

export default TestResult;
